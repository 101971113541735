import React, { useEffect, useState } from 'react'
import { isEmpty, get, toLower } from 'lodash'
import { initAxios } from '@plugins/axios'
import { Main, StaticPage } from '@pages'
import AuthenticationError from '@components/Authentication/AuthenticationError'
import MaintenancePage from 'components/Maintenance/MaintenancePage'
import ScrollToTop from '@components/ScrollToTop'
import NoMatch from '@components/NoMatch'
import { checkToken } from '@api'
import { CircularProgress } from '@chakra-ui/progress'
import queryString from 'query-string'
import { ThemeProvider, ColorModeProvider, extendTheme } from '@chakra-ui/react'
import { Box, Center } from '@chakra-ui/layout'
import Header from '@components/Header'
import Footer from '@components/Footer'
import i18n from '@i18n'
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom'

const isMaintenance = false

const theme = extendTheme({
  styles: {
    global: () => ({
      a: {
        color: '#dc3545',
        _hover: {
          textDecoration: 'underline',
        },
      },
    }),
  },

  config: {
    useSystemColorMode: false,
    initialColorMode: 'light',
    cssVarPrefix: 'michelin',
  },
  colors: {
    michelinPrimary: '#BD2333',
    michelin: '#BD2333',
  },
})

const App = () => {
  const [ready, setReady] = useState(false)
  const [isValidToken, setIsValidToken] = useState(false)
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)
  const [footerPage, setFooterPage] = useState(null)

  const checkValidToken = async () => {
    if (token) {
      localStorage.setItem('token', token)
      const [result, error] = await checkToken({ token })
      if (error) {
        //console.log('[App.checkValidToken]', error)
        setReady(true)
      }

      if (result && !isEmpty(result)) {
        const u = result[0]
        if (u) {
          setIsValidToken(true)
          setUser(u)

          let country = get(u, 'country', '')
          let newLanguage = 'en'

          switch (toLower(country)) {
            case 'canada':
              newLanguage = 'en-CA'
              break
            case 'hong kong':
              newLanguage = 'zh-HK'
              break
            case 'israel':
              newLanguage = 'en-IL'
              break
            case 'macao':
              newLanguage = 'zh-HK'
              break
            case 'malaysia':
              newLanguage = 'en-MY'
              break
            case 'mexico':
              newLanguage = 'es-MX'
              break
            case 'united arab emirates':
              newLanguage = 'en-US'
              break
            case 'singapore':
              newLanguage = 'en-SG'
              break
            default:
              newLanguage = get(u, 'spokenLanguages[0]', 'en')
              break
          }
          console.log('[App.checkValidToken] newLanguage', newLanguage)
          i18n.changeLanguage(toLower(newLanguage.replace('-', '_')))
        }
      } else {
        setIsValidToken(false)
      }
      setReady(true)
    }
  }

  useEffect(() => {
    checkValidToken()
  }, [token])

  useEffect(() => {
    if (initAxios()) {
      const params = queryString.parse(window.location.search)
      console.log('[App.useEffect] params', params)

      const path = get(window, 'location.pathname', '')
      if (/^\/[a-z]{2}-(cookie-policy||legal-notice||terms-of-use||privacy).html$/.test(path)) {
        setFooterPage(path.replace('/', ''))

        i18n.changeLanguage(path.substring(1, 3))
      }

      let tokenParam = get(params, 'token')
      console.log('[App.useEffect] tokenParam', params)

      if (tokenParam) {
        setToken(tokenParam)
      } else {
        tokenParam = localStorage.getItem('token')
        setToken(tokenParam)
      }

      if (!tokenParam) {
        setIsValidToken(false)
        setReady(true)
      }
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ColorModeProvider
        value="light"
        options={{
          useSystsemColorMode: false,
        }}>
        <Router>
          <ScrollToTop />
          {ready ? (
            <>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Layout />
                    </>
                  }>
                  {isMaintenance ? (
                    <>
                      <Route index element={<MaintenancePage />} />

                      <Route
                        path="*"
                        element={
                          <>
                            {footerPage ? (
                              <StaticPage footerPage={footerPage} />
                            ) : (
                              <MaintenancePage />
                            )}
                          </>
                        }
                      />
                    </>
                  ) : isValidToken ? (
                    <>
                      <Route index element={<Main user={user} />} />
                      <Route
                        path="/terms-of-use"
                        element={<StaticPage pageName="terms-of-use-html-content" />}
                      />
                      <Route
                        path="/privacy-policy"
                        element={<StaticPage pageName="privacy-html-content" />}
                      />
                      <Route
                        path="/legal-notice"
                        element={<StaticPage pageName="legal-notice-html-content" />}
                      />
                      <Route
                        path="/cookie-policy"
                        element={<StaticPage pageName="cookie-policy-html-content" />}
                      />

                      <Route
                        path="*"
                        element={
                          <>{footerPage ? <StaticPage footerPage={footerPage} /> : <NoMatch />}</>
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Route index element={<AuthenticationError />} />

                      <Route
                        path="*"
                        element={
                          <>
                            {footerPage ? (
                              <StaticPage footerPage={footerPage} />
                            ) : (
                              <AuthenticationError />
                            )}
                          </>
                        }
                      />
                    </>
                  )}
                </Route>
              </Routes>
            </>
          ) : (
            <Center minHeight={['625px', '660px']}>
              <CircularProgress isIndeterminate={true} size={42} color="michelinPrimary" />
            </Center>
          )}
        </Router>
      </ColorModeProvider>
    </ThemeProvider>
  )
}

const Layout = () => {
  return (
    <Box bg="#E5E5E5" w="100%">
      <Header />
      <Box justify="center" minHeight={['625px', '660px']} bg="white" w="100%">
        <Outlet />
      </Box>
      <Footer />
    </Box>
  )
}

export default App
